import { inject, Injectable, Signal } from '@angular/core';
import { AccountStore } from "../data-access/state/account.store";
import { DateTime } from "luxon";

@Injectable({
  providedIn: 'root'
})
export class AccountLocalizationService {

  private accountStore = inject(AccountStore);

  constructor() { }

  get timezone(): Signal<string> {
    return this.accountStore.timezone;
  }

  createNewDateInAccountTimezone(date: number | string | Date): Date {
    const timezone = this.timezone();
    console.log('timezone', timezone);
    const dateTimeInLocalZone = DateTime.fromJSDate(new Date(date));
    console.log('dateTimeInLocalZone', dateTimeInLocalZone);
    const dateTimeInAccountZone = dateTimeInLocalZone.setZone(timezone);
    console.log('dateTimeInAccountZone', dateTimeInAccountZone);
    return dateTimeInAccountZone.toJSDate();
  }

  currentDateInAccountTimezone(): Date {
    return this.createNewDateInAccountTimezone(Date.now());
  }

  convertDateValuesToAccountTimezone(date: Date): Date {
    const result = DateTime.fromJSDate(date).setZone(this.timezone(), { keepLocalTime: true }).toJSDate();
    console.log('convertDateValuesToAccountTimezone', date, result);
    return result;
  }

  /**
   * Takes a date, and converts (only) the values to the browser timezone from a selected timezone.
   */
  convertDateValuesFromAccountTimezone(date: Date): Date {
    const dateTime = DateTime.fromJSDate(date).setZone(this.timezone());
    return dateTime.setZone(DateTime.local().zoneName, { keepLocalTime: true }).toJSDate();
  }
}
